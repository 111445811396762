import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import { FormattedMessage } from "gatsby-plugin-intl"

const Question = () => (
  <Layout>
    <Header />
    <section className="page">
      <div className="container">
        <h1>
          <FormattedMessage id="howQuestion" />
        </h1>

        <p>
          <FormattedMessage id="question" />
        </p>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionA" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionAA" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionB" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionBB" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionC" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionCC" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionD" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionDD" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionE" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionEE" />
            </p>
          </div>
        </div>
        <div className="questionBox">
          <div className="question">
            <p>
              <FormattedMessage id="questionF" />
            </p>
          </div>
          <div className="answer">
            <p>
              <FormattedMessage id="questionFF" />
            </p>
          </div>
        </div>
        <div className="questionEmail">
          <p>
            <FormattedMessage id="questionEnd" />{" "}
            <a href="mailto:dotaz@zivlovka.cz">dotaz@zivlovka.cz</a>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Question
